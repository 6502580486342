/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */


require('./bootstrap');


import 'jquery-ui/ui/widgets/datepicker.js';
import 'jquery-ui/ui/widgets/sortable.js';
import 'jquery-ui/ui/widgets/autocomplete.js';
import 'bs-custom-file-input/dist/bs-custom-file-input.js';
import 'dropzone/dist/dropzone.js';
//import 'multiselect/js/jquery.multi-select.js'; 

// require('tinymce');
// require('tinymce/themes/mobile');
// require('tinymce/themes/silver');
// require('tinymce/plugins/paste');
// require('tinymce/plugins/advlist');
// require('tinymce/plugins/autolink');
// require('tinymce/plugins/lists');
// require('tinymce/plugins/link');
// require('tinymce/plugins/image');
// require('tinymce/plugins/charmap');
// require('tinymce/plugins/print');
// require('tinymce/plugins/preview');
// require('tinymce/plugins/anchor');
// require('tinymce/plugins/textcolor');
// require('tinymce/plugins/searchreplace');
// require('tinymce/plugins/visualblocks');
// require('tinymce/plugins/code');
// require('tinymce/plugins/fullscreen');
// require('tinymce/plugins/insertdatetime');
// require('tinymce/plugins/media');
// require('tinymce/plugins/table');
// require('tinymce/plugins/contextmenu');
// require('tinymce/plugins/code');
// require('tinymce/plugins/help');
// require('tinymce/plugins/wordcount');
// require('tinymce/icons/default');

window.bsCustomFileInput = require('bs-custom-file-input');
window.multiSelect = require('multiselect');
window.dropzone = require('dropzone');

window.Vue = require('vue');


// Vue.config.productionTip = false;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// const app = new Vue({
//     el: '#app',
// });
